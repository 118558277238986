import React  from 'react'
import styled from '@emotion/styled'
import HeroHeadline from '../components/NewHeader/HeroHeadline';

const StyledStandardContentWrapper = styled.div`   
  background-color:  ${({ theme }) => theme.mi_background_real};
  padding: 48px 24px 72px 24px;
  max-width:1140px;
  overflow: scroll;
  @media(min-width: 1140px){
    margin: 0 auto;
  }
  `

const PageTemplate = (props) => {
  return (
    <>
      <HeroHeadline key="404title" title="Seite nicht gefunden" subtitle="Fehlt was im Kiez?" />
      <StyledStandardContentWrapper>
        <p>Diese Seite existiert leider nicht. Vielleicht hat sie einmal existiert. Vielleicht wird sie einmal existieren. Vielleicht würde sie aber auch vorziehen, es nicht zu tun.</p>
      </StyledStandardContentWrapper>
    </>
  )
}




export default PageTemplate
